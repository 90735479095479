$color-blue: #2c80ff;
$color-yellow: #fdca40;
$color-red: #df2935;
$color-black: #080708;

body {
  font-family: sans-serif;
  background-color: $color-blue;
  color: $color-yellow;
}

.welcome {
  padding-left: 40px;
}
